.fside-menu {
    width: 15vw;
    min-width: 200px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #f0f0f0;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .fside-menu.active {
    transform: translateX(0);
  }
  
  .fside-menu:not(.active) {
    transform: translateX(-100%);
  }
  

.background-logo {
    position: relative;
}

.background-logo::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.3;
    z-index: -1;
}

.reactflow-wrapper {
    width: 100%;
    height: 100vh;
    background-size: 200% 200%;
    background-image: linear-gradient(135deg, #e2e2e2 0%, #ffffff 25%, #e2e2e2 50%, #ffeeee 75%, #ffffff 100%);
    animation: gradient 3s linear infinite;
}

@keyframes gradient {
    0% {background-position: 100% 0%;}
    50% {background-position: 0% 100%;}
    100% {background-position: 100% 0%;}
}
  