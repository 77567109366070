/* Normal state */
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    -webkit-transition: all 0.25s ease-in;
    -moz-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    opacity: 0.8; /* Make arrows slightly more opaque */
    position: absolute;
    z-index: 2;
    color: #000; /* Arrow color */
    background: rgba(101, 99, 126, 0.8); /* Adjust background opacity for visibility */
    border: 0;
    font-size: 32px; /* Adjust size of arrows if needed */
    cursor: pointer;
    padding: 40px; /* Increase padding for larger clickable area */
    margin: 0px; /* Increase margin to move arrows further from the edges */
    border-radius: 5%; /* Optional: make the background circle for arrows */
    top: 50%; /* Adjust vertical position to center arrows along the height */
    transform: translateY(-50%); /* Ensure arrows are centered */
}

/* Hover state */
.carousel .control-next:hover, .carousel .control-prev:hover {
    /* Change the color and opacity on hover */
    color: #242331 !important;
    opacity: 1 !important;
    background: rgba(0, 0, 0, 0.8) !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #109e23;
}

input:focus + .slider {
    box-shadow: 0 0 1px #21f344;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
