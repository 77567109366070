@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.wave-animation {
  animation: wave 1s infinite;
}

/* JUST Sans Regular */
@font-face {
  font-family: 'JUST Sans';
  src: url('./assets/fonts/JUST Sans Regular.woff2') format('woff2'),
       url('./assets/fonts/JUST Sans Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* JUST Sans Medium */
@font-face {
  font-family: 'JUST Sans';
  src: url('./assets/fonts/JUST Sans Medium.woff2') format('woff2'),
       url('./assets/fonts/JUST Sans Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* JUST Sans SemiBold */
@font-face {
  font-family: 'JUST Sans';
  src: url('./assets/fonts/JUST Sans SemiBold.woff2') format('woff2'),
       url('./assets/fonts/JUST Sans SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

/* JUST Sans Bold */
@font-face {
  font-family: 'JUST Sans';
  src: url('./assets/fonts/JUST Sans Bold.woff2') format('woff2'),
       url('./assets/fonts/JUST Sans Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/* JUST Sans ExtraBold */
@font-face {
  font-family: 'JUST Sans';
  src: url('./assets/fonts/JUST Sans ExBold.woff2') format('woff2'),
       url('./assets/fonts/JUST Sans ExBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

/* JUST Sans ExtraLight */
@font-face {
  font-family: 'JUST Sans';
  src: url('./assets/fonts/JUST Sans ExLight.woff2') format('woff2'),
       url('./assets/fonts/JUST Sans ExLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

/* JUST Sans Light */
@font-face {
  font-family: 'JUST Sans';
  src: url('./assets/fonts/JUST Sans Light.woff2') format('woff2'),
       url('./assets/fonts/JUST Sans Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
