.react-flow__node-custom {
  font-size: 8px;
  width: 60px;
  height: 25px;
  background: #f5f5f6;
  color: #222;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  border-radius: 2px;
  text-align: center; /* centers the text horizontally */
  display: flex; /* enables flex layout */
  justify-content: center; /* centers the text vertically in flex layout */
  align-items: center; /* centers the text horizontally in flex layout */
}
  
  .react-flow__node-custom .react-flow__handle {
    top: 24px;
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }

  .react-flow__node-custom .react-flow__handle-side {
    top: 50%;
    transform: translateY(-50%);
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }
  
  .react-flow__node-custom .react-flow__handle-top {
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 6px;
    border-radius: 2px;
    background-color: #778899;
  }
  
  .react-flow__node-custom .react-flow__handle-bottom {
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 6px;
    border-radius: 2px;
    background-color: #778899;
  }
  
  .react-flow__node.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
  
  .react-flow__node.annotation {
    border-radius: 0;
    text-align: left;
    background: white;
    border: none;
    line-height: 1.4;
    width: 225px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  }
  
  .react-flow__node.annotation .react-flow__handle {
    display: none;
  }
  
  .custom-node__header {
    padding: 8px 10px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .custom-node__body {
    padding: 10px;
  }
  
  .custom-node__select {
    position: relative;
    margin-bottom: 10px;
  }
  
  .custom-node__select select {
    width: 100%;
    margin-top: 5px;
    font-size: 10px;
  }
  
  /* dropped nodes styling */
  .custom-node2 {
    font-size: 0.5vw;
    background: rgba(245, 245, 246, 0);
    color: #222;
    border-radius: 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .custom-node {
    font-size: 0.5vw;
    width: 100px;
    height: 30px;
    background: rgba(245, 245, 246, 0);
    color: #222;
    border-radius: 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  /* Handles styling */
  .custom-node2 .handle-top,
  .custom-node .handle-right {
    background-color: #778899;
  }
  
  .custom-node2 .handle-bottom,
  .custom-node .handle-left {
    background-color: #4682b4;
  }
  
  /* Positioning */
  .custom-node2 .handle-top {
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 6px;
    border-radius: 2px;
  }
  
  .custom-node2 .handle-right {
    top: 50%;
    right: -4px;
    transform: translateY(-50%);
    width: 6px;
    height: 10px;
    border-radius: 2px;
  }
  
  .custom-node2 .handle-bottom {
    top: 98%;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 6px;
    border-radius: 2px;
  }
  
  .custom-node2 .handle-left {
    top: 50%;
    left: -4px;
    transform: translateY(-50%);
    width: 6px;
    height: 10px;
    border-radius: 2px;
  }
  
  
  /* Adding custom styles for selected nodes */
.custom-node2--selected {
  border: 2px solid rgb(11, 153, 18) !important; /* Override to ensure visibility */
}

/* Adding custom styles for selected edges */
.custom-node__edge--selected {
  stroke: red !important; /* Override to ensure visibility */
}

/* fishCss.css */
.react-flow__edge--selected path {
  stroke: #ff0000 !important; /* Change to your desired color */
  stroke-width: 2px !important; /* Adjust the stroke width if needed */
}

.custom-node--.dragging {
  padding: 10px 10px;
}