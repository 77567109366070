.custom * {
    padding: 0;
    margin: 0;
    z-index: 0;
}

.custom .container {
    width: 100%;
    min-height: 100vh;
    background: #333;
}

.custom .image {
    perspective: 3000px;
    width: 50%;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
}

.custom .image img {
    transform: rotateX(70deg) rotateZ(-60deg) translate3d(-120px, 0px, 70px);
    box-shadow: -80px 60px 15px 5px rgba(0,0,0,0.4);
    transition: all .4s;
    transform-style: preserve-3d;
    opacity: 0.1;
}

@media (max-width: 640px) {
  .custom .container {
    min-height: 150vh;
  }

  .custom .image {
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .custom .image img {
    transform: none;
    box-shadow: none;
    opacity: 0.15;
    max-width: 100%;
    height: auto;
  }
}

.custom .image:hover img {
    transform: rotateX(0deg) rotateZ(0deg) translate3d(0px, 0px, 0px);
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
    opacity: 0.8;
}
  
@keyframes radiate {
    0% {
        box-shadow: 0 0 0 0 rgba(163, 240, 163, 0.4);
    }
    70% {
        box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes radiate2 {
    0% {
        box-shadow: 0 0 0 0 rgba(163, 240, 163, 0.4);
    }
    70% {
        box-shadow: 0 10px 0 30px rgba(255, 255, 255, 0); /* Adjusted the vertical offset to 10px */
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.radiate {
    animation: radiate 1.5s infinite;
}

.grecaptcha-badge {
    right: unset !important;
    left: -50px !important; /* Adjust this value as needed */
    transform: scale(0.5);
    opacity: 0.6; /* Makes the badge less obvious */
    filter: hue-rotate(270deg); /* Changes the color to purple */
    z-index: 1000; /* Adjust this value as needed */
}

/* Hide on screens smaller than 600px */
@media screen and (max-width: 600px) {
    .grecaptcha-badge {
        display: none !important;
    }
}

.ticked:before {
    content: "\2713" !important;
    color: green !important;
    margin-right: 5px !important;
}

.crossed:before {
    content: "\2717" !important;
    color: red !important;
    margin-right: 5px !important;
}

ul.text-gray-500.text-sm li {
    margin-bottom: -5px;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Hide the SVG when it moves out of the box */
}

.animated-fist {
    position: absolute;
    left: -100%; /* Start from outside the box */
    animation: moveFist 6s linear infinite; /* Define the animation */
    height: 100px;
    width: auto;
}

@keyframes moveFist {
    0% {
        left: 0%;
        transform: scaleX(1);
    }
    50% {
        left: 100%;
        transform: scaleX(-1);
    }
    100% {
        left: 0%;
        transform: scaleX(1);
    }

    0% {
        right: 100%;
        transform: scaleX(1);
    }
    50% {
        right: 0%;
        transform: scaleX(-1);
    }
    100% {
        right: 100%;
        transform: scaleX(1);
    }
}

.AppBackground {
    background-image: 
      radial-gradient(at top left, #639, transparent 70%),
      radial-gradient(at top right, #f06, transparent 70%),
      radial-gradient(at bottom right, rgb(68, 68, 68), transparent 97%),
      radial-gradient(at bottom left, rgb(58, 58, 58), transparent 97%);
    background-size: cover;
    height: 100vh; /* Ensure full viewport height */
  }


  .loginformbg {
    background-image: 
      radial-gradient(at top left, rgb(47, 24, 70), transparent 70%),
      radial-gradient(at top right, rgba(110, 2, 45, 0.952), transparent 70%),
      radial-gradient(at bottom right, rgb(31, 30, 30), transparent 90%),
      radial-gradient(at bottom left, rgb(17, 17, 17), transparent 90%);
    background-size: cover;
  }
  
  .videoStyle {
    border: 3px solid #333; 
    border-radius: 20px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }
  
  .headfoot {
    background-image: 
      radial-gradient(at top left, rgb(22, 6, 37), transparent 95%),
      radial-gradient(at top right, rgba(44, 1, 18, 0.952), transparent 95%),
      radial-gradient(at bottom right, rgb(31, 30, 30), transparent 95%),
      radial-gradient(at bottom left, rgb(17, 17, 17), transparent 95%);
    background-size: cover;
  }

.menu-bg-1 {
    background-image: 
        radial-gradient(at top left, rgb(35, 40, 46), transparent 95%), /* gray-800 */
        radial-gradient(at top right, rgb(96, 105, 119), transparent 95%), /* gray-500 */
        radial-gradient(at bottom right, rgb(23, 25, 29), transparent 95%), /* gray-400 */
        radial-gradient(at bottom left, rgb(84, 91, 105), transparent 95%); /* gray-600 */
    background-size: cover;
}

.feather-effect {
    box-shadow: 0 0 10px 15px rgb(151, 151, 151);
}

/* svgStyles.css */
.st0 {
    fill: #FFFFFF;
  }
  
  .st1 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 5;
    stroke-miterlimit: 10;
  }
  

  .mentor-feedback-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5); /* Semi-transparent gray */
  }
  
@keyframes pulse-mentor {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
        transform: scale(1);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        transform: scale(1.2);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transform: scale(1);
    }
}

.loader-mentor {
    padding: 20px;
    background-color: #fff; /* Background color for the loader message */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(2, 197, 44, 0.2); /* Initial shadow */
    animation: pulse-mentor 2s infinite; /* Apply the pulse animation */
}
  
.progress-bar-vertical {
    width: 20px;
    height: 40px;
    background-color: #dadada;
    position: relative;
}

.progress-bar-fill {
    background-color: #03880e;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.shadow-inner {
    box-shadow: inset 0 0 5px #4b4392;
}

.guide-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .guide-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
  }
  
  .saveButton {
    background-color: #007bff; /* Your initial button color */
    color: white;
    /* Other styling properties */
  }
  
  .saveButtonSuccess {
    background-color: limegreen; /* Color indicating success */
    color: white;
    /* Other styling properties */
  }
  
@keyframes rounded-animation {
    0% { border-radius: 30% 0 30% 0; }
    50% { border-radius: 0 30% 0 30%; }
    100% { border-radius: 30% 0 30% 0; }
}

.rounded-animation {
    animation: rounded-animation 10s infinite;
}

.text-xxs {
    font-size: 0.5rem; /* or any size you desire */
  }
  

  /* waiting response */

  @keyframes waveAnimation {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.loading-chat-bubble {
    position: relative;
    overflow: hidden;
}

.loading-chat-bubble::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background-image: 
        radial-gradient(at bottom left, rgb(58, 68, 112), transparent 95%);
    background-size: 200% 200%;
    animation: waveAnimation 2s linear infinite;
}





  